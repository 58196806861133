/*
font-family: 'Plus Jakarta Display';
*/
@font-face {
  font-family: "Plus Jakarta Display";
  src: url("../fonts/PlusJakartaDisplay-Light.eot");
  src: url("../fonts/PlusJakartaDisplay-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/PlusJakartaDisplay-Light.woff2") format("woff2"),
    url("../fonts/PlusJakartaDisplay-Light.woff") format("woff"),
    url("../fonts/PlusJakartaDisplay-Light.ttf") format("truetype"),
    url("../fonts/PlusJakartaDisplay-Light.svg#PlusJakartaDisplay-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Display";
  src: url("../fonts/PlusJakartaDisplay-Italic.eot");
  src: url("../fonts/PlusJakartaDisplay-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/PlusJakartaDisplay-Italic.woff2") format("woff2"),
    url("../fonts/PlusJakartaDisplay-Italic.woff") format("woff"),
    url("../fonts/PlusJakartaDisplay-Italic.ttf") format("truetype"),
    url("../fonts/PlusJakartaDisplay-Italic.svg#PlusJakartaDisplay-Italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Display";
  src: url("../fonts/PlusJakartaDisplay-Regular.eot");
  src: url("../fonts/PlusJakartaDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/PlusJakartaDisplay-Regular.woff2") format("woff2"),
    url("../fonts/PlusJakartaDisplay-Regular.woff") format("woff"),
    url("../fonts/PlusJakartaDisplay-Regular.ttf") format("truetype"),
    url("../fonts/PlusJakartaDisplay-Regular.svg#PlusJakartaDisplay-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Display";
  src: url("../fonts/PlusJakartaDisplay-Medium.eot");
  src: url("../fonts/PlusJakartaDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/PlusJakartaDisplay-Medium.woff2") format("woff2"),
    url("../fonts/PlusJakartaDisplay-Medium.woff") format("woff"),
    url("../fonts/PlusJakartaDisplay-Medium.ttf") format("truetype"),
    url("../fonts/PlusJakartaDisplay-Medium.svg#PlusJakartaDisplay-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Display";
  src: url("../fonts/PlusJakartaDisplay-Bold.eot");
  src: url("../fonts/PlusJakartaDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/PlusJakartaDisplay-Bold.woff2") format("woff2"),
    url("../fonts/PlusJakartaDisplay-Bold.woff") format("woff"),
    url("../fonts/PlusJakartaDisplay-Bold.ttf") format("truetype"),
    url("../fonts/PlusJakartaDisplay-Bold.svg#PlusJakartaDisplay-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #a0a0a0;
  font-family: "Plus Jakarta Display";
}
a {
  outline: none !important;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
a:hover,
a:focus,
select:focus,
button:focus,
.btn:focus,
btn.focus {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
* {
  margin: 0;
  padding: 0;
}
img {
  border: none;
  vertical-align: middle;
  max-width: 100%;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  outline: none !important;
  color: #17181c;
}
input,
select,
textarea,
button {
  outline: none !important;
}
input,
textarea,
select {
  /*-moz-appearance: none; -webkit-appearance: none;*/
}
.container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0;
}

p {
  font-size: 16px;
  /* color: #a0a0a0; */
  color: #000;
  line-height: 26px;
}
textarea {
  font-family: inherit;
}

.btns {
  margin: 0;
  padding: 15px 25px 17px 25px;
  font-size: 15px;
  color: #fff;
  background: #024aad;
  border-radius: 50px;
  min-width: 195px;
  display: inline-block;
  border: none;
  text-align: center;
}
.btns:hover {
  background: #0d0734;
  color: #fff;
}
.btns.h_white:hover {
  background: #fff;
  color: #0d0734;
}
.btns.white {
  margin-top: 50px;
  background: #fff;
  color: #0d0734;
}

.btns.btns2 {
  background: #fff;
  color: #024AAD;
}
.btns.btns2:hover {
  color: #0d0734;
  background: #fff;
}

.global_title {
  margin: 0 0 60px 0;
  padding: 0;
  font-size: 45px;
}
.global_title span {
  color: #024aad;
  font-weight: 300;
  font-style: italic;
}
.global_title small {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 300;
  display: block;
}
.global_title.white {
  color: #fff;
}



/*  comment style */

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 1.25rem;
}

.avatar-img,
.avatar-initials,
.avatar-placeholder {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.avatar-img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-initials {
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  line-height: 0;
  background-color: #a0aec0;
}

.avatar-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  background: #a0aec0
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='%23fff' d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'/%3e%3c/svg%3e")
    no-repeat center/1.75rem;
}

.avatar-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20%;
  height: 20%;
  display: block;
  background-color: #a0aec0;
  border-radius: 50%;
}

.avatar-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

.avatar-sm,
.avatar-group-sm > .avatar {
  width: 2.125rem;
  height: 2.125rem;
  font-size: 1rem;
}

.avatar-sm .avatar-placeholder,
.avatar-group-sm > .avatar .avatar-placeholder {
  background-size: 1.25rem;
}

.avatar-group-sm > .avatar + .avatar {
  margin-left: -0.53125rem;
}

.avatar-lg,
.avatar-group-lg > .avatar {
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
}

.avatar-lg .avatar-placeholder,
.avatar-group-lg > .avatar .avatar-placeholder {
  background-size: 2.25rem;
}

.avatar-group-lg > .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-light .avatar-indicator {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75);
}

.avatar-group-light > .avatar {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75);
}

.avatar-dark .avatar-indicator {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25);
}

.avatar-group-dark > .avatar {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25);
}

/* Font not working in <textarea> for this version of bs */

textarea {
  font-family: inherit;
}


/***********  Top Part  ************/
header {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 999;
}

.nav_sec {
  width: 100%;
  float: left;
  padding: 24px 0;
  margin: 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}
.nav_sec:before {
  content: "";
  width: 100px;
  height: 4px;
  background: #024aad;
  border-radius: 50px 0 0 50px;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.nav_sec .navbar-brand {
  margin: 0;
  padding: 0;
}
.nav_sec .navbar {
  padding: 0;
}
.nav_sec .navbar-light .navbar-nav > li {
  margin: 0 30px 0 0;
  padding: 0;
}
.nav_sec .navbar-light .navbar-nav > li.get_btn {
  margin-left: 7px;
  margin-right: 0;
}
.nav_sec .navbar-light .navbar-nav .nav-link {
  padding: 10px 0 14px 0;
  font-size: 14px;
  color: #a0a0a0;
  position: relative;
}
.nav_sec .navbar-light .navbar-nav .nav-link:before {
  content: "";
  width: 0;
  height: 2px;
  background: #024aad;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 7px;
  margin: auto;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.nav_sec .navbar-light .navbar-nav .get_btn a {
  padding: 10px 21px 14px 21px;
  font-size: 14px;
  background: #024aad;
  color: #fff;
  border-radius: 50px;
  display: block;
  text-align: center;
}
.nav_sec .navbar-light .navbar-nav .nav-link:hover,
.nav_sec .navbar-light .navbar-nav .nav-link:focus,
.nav_sec .navbar-light .navbar-nav .show > .nav-link,
.nav_sec .navbar-light .navbar-nav .active > .nav-link,
.nav_sec .navbar-light .navbar-nav .nav-link.show,
.nav_sec .navbar-light .navbar-nav .nav-link.active {
  color: #024aad;
  border-color: #024aad;
}

.nav_sec .navbar-light .navbar-nav .nav-link:hover:before,
.nav_sec .navbar-light .navbar-nav .nav-link:focus:before,
.nav_sec .navbar-light .navbar-nav .show > .nav-link:before,
.nav_sec .navbar-light .navbar-nav .active > .nav-link:before,
.nav_sec .navbar-light .navbar-nav .nav-link.show:before,
.nav_sec .navbar-light .navbar-nav .nav-link.active:before {
  width: 25px;
}

.nav_sec .navbar-light .navbar-nav .get_btn a:hover {
  background: #17181c;
  color: #fff;
}

.nav_sec .dropdown-menu {
  margin-top: 0;
  border-radius: 0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border: none;
}
.nav_sec .dropdown-item {
  padding: 8px 20px;
  font-size: 14px;
}
.nav_sec .dropdown-item:hover,
.nav_sec .dropdown-item.active {
  background: #024aad;
  color: #fff;
}

/************* Section ***********/
section {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}

/***** Hero Sec ******/
.hero_sec {
  width: 100%;
  float: left;
  padding: 33px 0 24px 0;
  margin: 0;
  background: #fcfcfc;
  position: relative;
}
.hero_sec .detail {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0 0 80px 0;
  position: relative;
  z-index: 5;
}
.hero_sec h1 {
  margin: 0 0 13px 0;
  padding: 0;
  font-size: 60px;
  color: #17181c;
  font-weight: 500;
}
.hero_sec h1 span {
  color: #024aad;
  font-weight: normal;
  font-style: italic;
}
.hero_sec p {
  margin: 0 0 49px 0;
  padding: 0;
  font-size: 21px;
  font-weight: 300;
  line-height: 35px;
  max-width: 530px;
}
.hero_sec figure {
  margin: 0 0 0 -125px;
  padding: 0;
}

.clearfix {
  width: 100%;
  display: block;
  clear: both;
  margin: 0;
}

.scroll_down {
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  bottom: -120px;
}

/***** Intent Sec ******/
.intent_sec {
  width: 100%;
  float: left;
  margin: 0;
  padding: 110px 0;
}
.intent_sec1 {
  width: 100%;
  float: left;
  margin: 0;
  /* padding: 110px 0; */
}
.intent_sec1 .inner {
  width: 100%;
  float: left;
  margin: 0;
  padding: 41px 57px 80px 35px;
  background: #024aad;
}
.intent_sec .inner {
  width: 100%;
  float: left;
  margin: 0;
  padding: 41px 57px 80px 35px;
  background: #024aad;
}
.intent_sec h2 {
  margin: 0 0 27px 0;
  padding: 0;
  font-size: 40px;
  color: #ffffff;
  font-weight: 300;
}
.intent_sec p {
  margin: 0;
  padding: 0;
  max-width: 499px;
}
.intent_sec .intent {
  width: 68%;
  float: left;
  margin: 35px 0 0 0;
  padding: 17px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.18);
  max-width: 307px;
}
.intent_sec .intent h3 {
  margin: 0 0 14px 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}
.intent_sec .intent h3 span {
  color: #024aad;
  font-weight: 300;
  font-style: italic;
}
.intent_sec .intent .int_detail {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
}
.intent_sec .intent figure {
  width: 60px;
  flex: 0 0 60px;
  height: 60px;
  border-radius: 50%;
  float: left;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #024aad;
  box-shadow: 0 7px 10px rgba(2, 74, 173, 0.12);
}
.intent_sec .intent figure.black {
  background: #0d0734;
}
.intent_sec .intent h4 {
  margin: 0;
  padding: 0 0 0 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
/***** Welcome Sec *****/
.welcome_sec {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}
.welcome_sec .container {
  max-width: 1040px;
}
.welcome_sec .detail {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}
.welcome_sec h3 {
  margin: 0 0 21px 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
}
.welcome_sec p {
  margin: 0;
  padding: 0;
}
.welcome_sec p span {
  font-style: italic;
  margin-bottom: 18px;
  display: block;
}
.welcome_sec figure {
  margin: 0;
  padding: 0;
}
.welcome_sec .row {
  margin-bottom: 100px;
}

/***** Built Sec *****/
.built_sec {
  width: 100%;
  float: left;
  margin: 0;
  padding: 100px 0 90px 0;
  background: #fbfbfb url(../images/built_curve.svg) no-repeat left -78px bottom -50px;
}
.mgnt_section {
  width: 100%;
  float: left;
  margin: 0;
  padding: 100px 0 90px 0;
}
.mgnt_image{
  height: 274px;
}
.name_style{
  color: rgba(2,74,173,1);
  margin-top: 10px;
  margin-bottom:0px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.name_style2{
  font-size: 15px;
    color: rgba(2,74,173,1);
    font-weight: 500;
    margin-top: -8px;
}
.position{
  color: rgba(46,46,46,1);
  font-size: 12px;
}
.position2{
  color: rgba(46,46,46,1);
  font-size: 12px;
  margin-top: -25px;
}
.img_twitter{
  height: 35px;
  margin: 5px;
}
.modal-dialog{
  max-width:1000px !important;
}
.blue_line{
  border-left: 3px solid #1658B3;
}

.image_align{
  /* padding-left: 10px; */
  text-align: center;
}
@media (min-width: 992px) {
  .image_align  {
    padding-left: 80px;
  }
}
.specing17.row {
  margin: 0 -8.5px;
}
.specing17.row > div {
  padding: 0 8.5px;
  margin-bottom: 17px;
}

.built_block {
  width: 100%;
  float: left;
  margin: 0;
  padding: 45px 30px 40px 30px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}
.built_block figure {
  margin: 0 0 20px 0;
  padding: 0;
  transition: all 0.4s ease-in-out;
}
.built_block .detail {
  margin: 0;
  padding: 0;
}
.built_block h3 {
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.4s ease-in-out;
}
.built_block p {
  margin: 0;
  padding: 0;
  transition: all 0.4s ease-in-out;
}

.built_block1 {
  width: 100%;
  float: left;
  margin: 0;
  box-shadow: 0 1px 3px 0;
  padding: 45px 30px 40px 30px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}
.built_block1:hover {
  background: #024aad;
  border-color: #024aad !important;
  box-shadow: 0 17px 18px rgba(0, 0, 0, 0.1);
}
.built_block1:hover img {
  color: #fff;
}
.built_block1:hover p {
  color: #fff;
}
.built_block1 p {
  margin: 1;
  padding: 0;
  transition: all 0.4s ease-in-out;
}





.built_block2 {
  width: 100%;
  float: left;
  margin: 0;
  box-shadow: 0 1px 3px 0;
  padding: 45px 30px 40px 30px;
  height: 75%;
  background: #fff;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}
.built_block2:hover {
  /* background: #024aad; */
  border-color: #024aad !important;
  box-shadow: 0 17px 18px rgba(0, 0, 0, 0.1);
}
/* .built_block2:hover img {
  color: #fff;
}
.built_block2:hover p {
  color: #fff;
} */
.built_block2 p {
  margin: 1;
  padding: 0;
  transition: all 0.4s ease-in-out;
}


.backgroundimage {
  background-image: url(../images/blog2.svg);
  height: 285px;
  width: 100%;
}
.fontsize {
  font-size: 15px;
  color: #fff;
  position: absolute;
  bottom: 15px;
  left: 25px;
}
.smallfontsize {
  font-size: 15px;
  color: #fff;
  position: absolute;
  bottom: 2px;
  left: 25px;
}

.input {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  border: 1px #f9f9f9;
  padding-left: 5px;
  height: 45px;
}
.textarea {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  border: 1px #f9f9f9;
  padding-left: 5px;
  height: 90px;
}

.built_block:hover {
  background: #024aad;
  border-color: #024aad !important;
  box-shadow: 0 17px 18px rgba(0, 0, 0, 0.1);
}
.built_block:hover figure {
  filter: contrast(0) brightness(100);
}
.built_block:hover h3 {
  color: #fff;
}
.built_block:hover p {
  color: #fff;
}

/****** Services Sec ******/
.services_sec {
  width: 100%;
  float: left;
  padding: 110px 0 70px 0;
  margin: 0;
}
.services_sec .container {
  max-width: 930px;
}
@media (min-width: 992px) {
  .services_sec .row {
    margin: 0 -30px;
  }
  .services_sec .row > div {
    padding: 0 30px;
  }
}
.services_sec h3 {
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
}
.services_sec p {
  margin: 0 0 45px 0;
  padding: 0;
}

/****** Network Sec *******/
.network_sec {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  background: #0d0734;
}
.sky_bg {
  background: #024aad;
}
.get_info {
  width: 100%;
  max-width: 598px;
  float: right;
  padding: 80px 40px 70px 20px;
  margin: 0;
}
.get_info h2 {
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 35px;
  color: #fff;
  font-weight: 500;
}
.get_info p {
  margin: 0 0 47px 0;
  padding: 0;
  color: #fff;
  line-height: 21px;
}

.net_info {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  background: #090429;
}
.net_info.net_detail {
  padding: 65px 20px 65px 50px;
  margin: 0;
  max-width: 421px;
  background: none;
}
.network_sec h3 {
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 21px;
  color: #fff;
  font-weight: 500;
}
.net_info p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  line-height: 18px;
}
.journey_info {
  width: 100%;
  float: left;
  padding: 45px 20px 45px 50px;
  margin: 0;
  max-width: 572px;
}
.journey_info h3 {
  margin: 0;
  padding: 0;
}
.journey_info p span {
  display: block;
  margin: 8px 0;
  font-style: italic;
}

/****** Solve Sec *******/
.solve_sec {
  width: 100%;
  float: left;
  padding: 110px 0 100px 0;
  margin: 0;
}
.solve_sec h3 {
  margin: 0 0 12px 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
}
.solve_sec .info {
  margin: 0 0 60px 0;
  padding: 0;
  font-size: 18px;
  line-height: 23px;
  font-weight: 300;
}
.solve_sec .built_block {
  padding: 27px 24px 20px 24px;
  border: 1px solid #ebebeb;
}

/****** Analyst Sec ******/
.analyst_sec {
  width: 100%;
  float: left;
  padding: 10px 0;
  margin: 0;
  background: #0d0734;
}
.analyst_sec .detail {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  max-width: 622px;
}
.analyst_sec h2 {
  margin: 0 0 17px 0;
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  color: #fff;
}
.analyst_sec p {
  margin: 0 0 33px 0;
  padding: 0;
  color: #fff;
}
.analyst_sec figure {
  width: 100%;
  max-width: 360px;
  min-height: 338px;
  background: #fff;
  margin: 0;
  padding: 0;
}

/****** Efficient Sec ******/
.efficient_sec {
  width: 100%;
  float: left;
  padding: 70px 0;
  margin: 0;
  border-bottom: 1px solid #f2f2f2;
}
.efficient_sec h3 {
  padding: 0;
  margin: 0 0 19px 0;
  font-size: 20px;
  font-weight: bold;
}
.efficient_sec p {
  margin: 0 0 22px 0;
  padding: 0 30px 0 0;
}
.efficient_sec figure {
  margin: 0 -30% 0 -50px;
  padding: 0;
}
.effic {
  width: 100%;
  float: left;
  margin: 0 0 8px 0;
  padding: 0;
}
.effic i {
  margin: 0 0 19px 0;
  padding: 0;
  font-style: normal;
  display: block;
}

/************** Newsletter Sec ***************/
.newsletter_sec {
  width: 100%;
  float: left;
  padding: 160px 0;
  margin: 0;
}

.newsletter_sec .detail {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}
.newsletter_sec p {
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 18px;
  line-height: 30px;
}
.newsletter_sec p span {
  color: #17181c;
  font-weight: 300;
  font-style: italic;
}
.newsletter_sec p strong {
  color: #17181c;
  font-size: 19px;
}
.news_form {
  width: 100%;
  max-width: 501px;
  float: right;
  padding: 57px 40px 115px 40px;
  margin: 20px 0 0 0;
  background: url('../../v2/images/newslatterimage.svg');
  /* background: #0d0734; */
  border-radius: 12px;
}

.textinput{
  height: 45px;
  width: 400px;
}
.textinput1{
  height: 50px;
  width: 300px;
}
.news_form p {
  font-size: 16px;
  line-height: 26px;
}
.news_form .global_title {
  margin: 0 0 18px 0;
}
.news_form .field {
  width: 100%;
  height: 105px;
  float: left;
  padding: 10px;
  color: #fff;
  margin: 12px 0 24px 0;
  background: #0a052d;
  border: none;
  border-radius: 3px;
}

.news_form ::-webkit-input-placeholder {
  color: #fff;
}
.news_form ::-moz-placeholder {
  color: #fff;
}
.news_form :-ms-input-placeholder {
  color: #fff;
}
.news_form :-moz-placeholder {
  color: #fff;
}

/*************** Get Start *****************/
.get_start {
  width: 100%;
  float: left;
  padding: 80px 0;
  margin: 0;
  background: #024aad;
  text-align: center;
}
.get_start .container {
  max-width: 940px;
}
.get_start .global_title {
  margin-bottom: 18px;
}
.get_start p {
  font-size: 21px;
  color: #fff;
  padding: 0;
  margin: 0 0 35px 0;
  font-weight: 300;
  line-height: 35px;
}

/************* Footer ***********/
footer {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  background: #17181c;
}
.footer_detail {
  width: 100%;
  float: left;
  padding: 60px 0 80px 0;
  margin: 0;
}
.footer_logo {
  padding: 0;
  margin: 0 0 15px 0;
}
.footer_detail p {
  padding: 0;
  margin: 0 0 40px 0;
  font-size: 15px;
  color: #a0a0a0;
  line-height: 25px;
}
ul.social_info {
  padding: 0;
  margin: 0 0 25px 0;
}
ul.social_info li {
  padding: 0;
  margin: 0 20px 0 0;
  font-size: 25px;
  color: #a0a0a0;
  display: inline-block;
}
ul.social_info li a {
  color: #a0a0a0;
}
ul.social_info li a:hover {
  color: #024aad;
}

.footer_detail h3 {
  padding: 0;
  margin: 0 0 18px 0;
  font-size: 15px;
  color: #ffffff;
  text-transform: uppercase;
}
ul.footer_link {
  padding: 0;
  margin: 0;
}
ul.footer_link li {
  padding: 0;
  margin: 0 0 14px 0;
  font-size: 14px;
  color: #a0a0a0;
}
ul.footer_link li a {
  color: #a0a0a0;
}
ul.footer_link li a:hover {
  color: #024aad;
}

.copyright {
  width: 100%;
  float: left;
  padding: 16px 0;
  margin: 0;
  background: #202126;
  border-top: 1px solid #545559;
}
.copyright p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #a0a0a0;
}

/*================ News Page =================*/
.news_sec {
  width: 100%;
  float: left;
  margin: 0;
  padding: 80px 0 120px 0;
}
.news_block1 {
  width: 100%;
  float: left;
  margin: 0 0 30px 0;
  padding: 0;
  position: relative;
}
.news_block1 figure {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  border-radius: 3px;
}
.news_block1 figure img {
  width: 100%;
  border-radius: 3px;
}
.news_block1 .detail {
  width: 100%;
  float: left;
  margin: 0;
  padding: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.news_block1 .detail h3 {
  margin: 0 0 6px 0;
  padding: 0;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
.news_block1 .detail h3 a {
  color: #fff;
}
.news_block1 .detail h3 a:hover {
  color: #fff;
}
.news_block1 .detail span {
  margin: 0;
  padding: 0;
  color: #fff;
}

.news_block1.news_block2 {
  margin-bottom: 15px;
}

.news_sec .specing17 > div {
  margin-bottom: 0;
}
.news_sec .mb-100 {
  margin-bottom: 100px;
}
.news_sec .mb-120 {
  margin-bottom: 120px;
}
.news_sec .mb-80 {
  margin-bottom: 80px;
}

.news_block4 {
  width: 100%;
  float: left;
  margin: 0 0 30px 0;
  padding: 0;
}
.news_block4 figure {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  border-radius: 3px;
}
.news_block4 figure img {
  width: 100%;
  border-radius: 3px;
}
.news_block4 .detail {
  width: 100%;
  float: left;
  margin: 0;
  padding: 20px 20px 0 20px;
}
.news_block4 .detail h3 {
  margin: 0 0 6px 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}
.news_block4 .detail h3 a {
  color: #17181c;
}
.news_block4 .detail h3 a:hover {
  color: #17181c;
}
.news_block4 .detail span {
  margin: 0 0 13px 0;
  padding: 0;
  font-weight: 500;
  display: block;
}
.news_block4 .detail p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
}

/*================ Blog list Page ================*/
.dark {
  background: #080808;
}
.dark .nav_sec {
  border-color: rgba(253, 253, 253, 0.05);
}
.dark .nav_sec .navbar-brand img {
  filter: contrast(0) brightness(100);
}

.blog_list {
  width: 100%;
  float: left;
  margin: 0;
  padding: 100px 0 50px 0;
}
.blog_list .info {
  width: 100%;
  max-width: 547px;
  margin: 0 0 80px 0;
  padding: 0;
  font-size: 21px;
  color: #a0a0a0;
  line-height: 40px;
  font-weight: 300;
}

.blog-card-columns.card-columns {
  -webkit-column-gap: 17px;
  -moz-column-gap: 17px;
  column-gap: 17px;
  column-count: 3;
}
.blog-card-columns.card-columns .card {
  background: none;
  border: none;
  border-radius: 0;
  margin-bottom: 90px;
}
.blog-card-columns figure {
  margin: 0;
  padding: 0;
  position: relative;
  border-radius: 3px 3px 0 0;
  position: relative;
}
.blog-card-columns figure img {
  width: 100%;
  border-radius: 3px 3px 0 0;
}
.blog-card-columns figure:before {
  content: "";
  width: 100%;
  height: 140px;
  background: url(../images/blog_overly.png) no-repeat center top;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.blog-card-columns .detail {
  margin: 0;
  padding: 12px 60px 0 20px;
  position: relative;
}
.blog-card-columns .detail h3 {
  margin: 0 0 6px 0;
  padding: 0;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
.blog-card-columns .detail h3 a {
  color: #fff;
}
.blog-card-columns .detail h3 a:hover {
  color: #fff;
}
.blog-card-columns .detail span {
  margin: 0;
  padding: 0;
  color: #fff;
}
.blog-card-columns .more_link {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-card-columns .more_link:hover {
  right: 13px;
}

/*================ Blog Detail Page ================*/
.blog_detail {
  width: 100%;
  float: left;
  margin: 0;
  padding: 120px 0 100px 0;
}
.back_link {
  margin: 0 0 11px 0;
  padding: 0;
  font-size: 21px;
  color: #a0a0a0;
  display: inline-block;
}
.blog_detail .global_title {
  font-weight: normal;
}
.blog_detail .global_title strong span {
  color: #a0a0a0;
  font-weight: bold;
  font-style: normal;
}
.blog_detail figure {
  width: 100%;
  max-width: 971px;
  margin: 0 auto 79px auto;
  padding: 0;
  display: block;
}
.blog_detail .container.small_cont {
  max-width: 900px;
}
.blog_detail p {
  margin: 0 0 23px 0;
  padding: 0;
}
.blog_detail h3 {
  margin: 40px 0 17px 0;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}
.blog_detail h4 {
  margin: 50px 0 24px 0;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.blog_detail ol {
  margin: 0 0 53px 0;
  padding: 0;
}
.blog_detail ol li {
  margin: 0 0 20px 55px;
  padding: 0 0 0 10px;
  font-size: 16px;
  color: #a0a0a0;
  line-height: 26px;
  list-style: decimal-leading-zero;
}

.share_info {
  margin: 0;
  padding: 23px 0 0 0;
  border-top: 1px solid #202126;
}
.blog_detail .share_info h4 {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.share_info ul.social_info {
  float: right;
  margin: 0;
  padding: 0;
}
.share_info ul.social_info li:last-child {
  margin-right: 0;
}

.related_sec {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0 0 20px 0;
}
.related_sec h4 {
  margin: 0;
  padding: 22px 0 50px 0;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  border-top: 1px solid #202126;
}
.modal-header{
  border-bottom:0px !important;
}
.close{
  color: #fff !important;
  text-shadow:0px !important
}
