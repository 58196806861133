@media (max-width:1590px) {
.efficient_sec figure { margin: 0 -5% 0 -50px;}
}
@media (max-width:1300px) {
.container{ width:90%; margin:0 auto; padding:0;}
.nav_sec .navbar-light .navbar-nav > li{ margin-right:20px;}
.nav_sec { padding: 15px 0;}
.hero_sec h1 { font-size: 45px;}
.hero_sec p { font-size: 18px; line-height: 28px;}
.scroll_down { bottom: -100px;}
.intent_sec h2 { font-size: 34px;}
.intent_sec .intent { max-width: 267px;}
.global_title{ font-size:36px;}
.built_block h3{ font-size:18px;}
.services_sec h3{ font-size:18px;}
.get_info h2 { font-size: 30px;}
.get_info { padding: 50px 20px 40px 10%;}
.net_info.net_detail{ padding:65px 10% 65px 20px;}
.journey_info{ padding:45px 10% 45px 20px;}
.network_sec h3{ font-size: 18px;}
.get_info p{ margin-bottom:30px;}
.global_title small { font-size: 16px;}
.solve_sec h3{ font-size: 18px;}
.solve_sec .info{ font-size: 16px;}
.analyst_sec h2{ font-size: 28px;}
.efficient_sec figure{ margin:0;}
.efficient_sec h3{ font-size: 18px;}
.get_start p{ font-size: 18px;}
.newsletter_sec{ padding:100px 0;}
.newsletter_sec p{ font-size:16px; line-height:28px;}
.newsletter_sec p strong{ font-size:17px;}

.blog_list .info { max-width: 490px; margin: 0 0 40px 0; font-size: 18px; line-height: 34px;}
.back_link{ font-size:18px;}
}

/************ Main **************/
@media (max-width: 991px) {
p{ font-size:14px; line-height:23px;}

.navbar-light .navbar-toggler{ background:none; border:none; position:relative; right:0; top:0; height: 33px;}
.navbar-toggler:focus,.navbar-toggler:active { outline: 0;}
.navbar-toggler span {display: block; background-color:#3a373a; height:3px; width:25px; margin-top:4px; margin-bottom:4px; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg);-o-transform: rotate(0deg);  transform: rotate(0deg); position: relative; left: 0;opacity: 1;}
.navbar-toggler span:nth-child(1),.navbar-toggler span:nth-child(3) { -webkit-transition: transform .35s ease-in-out; -moz-transition: transform .35s ease-in-out; -o-transition: transform .35s ease-in-out; transition: transform .35s ease-in-out;}
.navbar-toggler:not(.collapsed) span:nth-child(1) { position: absolute; left: 12px; top: 10px; -webkit-transform: rotate(135deg);  -moz-transform: rotate(135deg); -o-transform: rotate(135deg);transform: rotate(135deg); opacity: 0.9;}
.navbar-toggler:not(.collapsed) span:nth-child(2) {height: 12px; visibility: hidden; background-color: transparent;}
.navbar-toggler:not(.collapsed) span:nth-child(3) {position: absolute;left: 12px;top: 10px;-webkit-transform: rotate(-135deg);-moz-transform: rotate(-135deg); -o-transform: rotate(-135deg);transform: rotate(-135deg);opacity: 0.9;}  
.nav_sec .navbar-light .navbar-nav > li{ margin:0;}
.nav_sec .navbar-light .navbar-nav > li.get_btn{ margin:10px 0 0 0;}
.nav_sec .navbar-light .navbar-nav .nav-link::before{ display:none;}
.nav_sec .navbar-light .navbar-nav .nav-link{ padding:8px 0 10px 0; border-bottom:1px solid rgba(0,0,0,0.1);}
.nav_sec .dropdown-menu{ box-shadow:none;}
.nav_sec .dropdown-item{ border-bottom:1px solid rgba(0,0,0,0.1);}
.hero_sec h1 { font-size: 30px;}
.hero_sec p { font-size: 16px; line-height: 26px;}
.scroll_down { bottom: -80px;}
.intent_sec{ padding:40px 0;}
.intent_sec h2 { font-size: 30px;}
.intent_sec .intent h3 { font-size: 16px;}
.intent_sec .inner{ padding:30px;}
.intent_sec .intent{ margin-top:30px;}
.welcome_sec .row { margin-bottom: 40px;}
.built_sec{ padding:40px 0 20px 0;}
.global_title { font-size: 30px; margin-bottom:30px;}
.built_block { padding: 30px 20px;}
.hero_sec p{ margin-bottom:30px;}
.services_sec { padding: 40px 0 20px 0;}
.services_sec p{ margin-bottom:30px;}
.get_info { padding: 40px 5%; max-width:100%;}
.net_info.net_detail{ padding: 40px 5%; max-width:100%;}
.journey_info{ padding: 40px 5%; max-width:100%;}
.solve_sec{ padding:40px 0 30px 0;}
.solve_sec h3 { font-size: 16px;}
.solve_sec .info{ font-size: 16px; margin-bottom:30px;}
.solve_sec .built_block { padding: 27px 14px 20px 14px;}
.analyst_sec h2 { font-size: 24px; line-height:normal;}
.efficient_sec{ padding:40px 0 10px 0;}
.efficient_sec h3{ font-size: 16px;}
.newsletter_sec{ padding:40px 0 20px 0;}
.news_form { max-width: 100%; padding: 35px 20px 30px 20px;}
.get_start{ padding:40px 0;}
.get_start p { font-size: 16px; line-height:23px;}
.footer_detail{ padding:40px 0 20px 0;}

.newsletter_sec p{ font-size:14px; line-height:23px;}
.newsletter_sec p strong{ font-size:16px;}
.news_form p{ font-size:14px; line-height:24px;}

.news_sec{ padding:40px 0 10px 0;}
.news_sec .mb-100{ margin-bottom:35px;}
.news_sec .mb-120{ margin-bottom:20px;}
.news_sec .mb-80{ margin-bottom:20px;}
.news_block4 .detail { padding:20px 0 0 0;}

.blog_list .info { max-width: 450px; margin: 0 0 30px 0; font-size: 16px; line-height: 28px;}
.blog-card-columns.card-columns .card{ margin-bottom:40px;}
.blog_list { padding: 40px 0 10px 0;}
.blog-card-columns .detail{ padding:15px 50px 0 0;}
.blog-card-columns .detail h3{ font-size:16px;}
.back_link{ font-size:16px;}
.blog_detail figure{ margin-bottom:30px;}
.blog_detail{ padding:40px 0;}
.blog_detail h3{ font-size:20px; margin:30px 0 17px 0;}
.blog_detail h4 { margin: 30px 0 24px 0; font-size: 16px;}
.blog_detail ol li { margin: 0 0 10px 40px; font-size: 14px; line-height: 23px;}
.related_sec h4 { padding: 22px 0 30px 0; font-size: 16px;}
}

@media (max-width: 767px) {
html { -webkit-text-size-adjust:none;}
.container{ width:90%; margin:0 auto; padding:0; max-width: inherit;}
.hero_sec .detail { margin: 0 0 30px 0;}
.scroll_down { bottom:0; margin-top:30px;}
.intent_sec p{ max-width:100%;}
.welcome_sec .detail{ text-align:center;}
.welcome_sec figure{ margin-top:30px; text-align:center !important;}
.analyst_sec .detail{ padding:30px 0;}
.analyst_sec figure{ max-width:100%; margin-bottom:30px;}
.efficient_sec figure{ margin-bottom:30px;}
.footer_detail p{ font-size:14px; margin: 0 0 30px 0;}
ul.social_info li { margin: 0 13px 0 0; font-size: 20px;}
.hero_sec p { font-size: 14px; line-height: 23px;}
.solve_sec .info{ font-size: 14px;}
.get_start p { font-size: 14px;}
.hero_sec figure { margin: 0 0 0 -85px;}
.intent_sec .inner { padding: 0;}
.intent_sec {margin-bottom:40px; background: #024AAD;}



.global_title { font-size: 28px;}
.get_info h2 { font-size: 26px;}

.blog-card-columns.card-columns{ column-count: 2;}
.blog_list .info { max-width: 100%; margin: 0 0 30px 0; font-size: 14px; line-height: 23px;}
}

/************ iphone **************/
@media (max-width:600px) {
html { -webkit-text-size-adjust:none;}
.newsletter_sec{ padding-bottom:40px;}
.blog-card-columns.card-columns{ column-count: 1;}
}